/* NoInternet.css */

.no-internet-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
    padding: 20px;
  }
  
  .no-internet-content {
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  
  .no-internet-image {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .no-internet-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .no-internet-message {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .retry-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .retry-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 480px) {
    .no-internet-content {
      padding: 20px;
    }
  
    .no-internet-title {
      font-size: 20px;
    }
  
    .no-internet-message {
      font-size: 14px;
    }
  
    .retry-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  